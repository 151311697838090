const loadScript = function (url) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.setAttribute('defer', '');
    script.setAttribute('crossorigin', '');
    document.body.appendChild(script);
}

window.addEventListener('load', () => {
    if (localStorage.getItem('wasOnAdminPage') === 'true') {
        loadScript('https://unpkg.com/netlify-identity-widget@1.9.1/build/netlify-identity-widget.js');
    }
})